#hero {
  background: url(assets/rishi.png);
  background-repeat: no-repeat;
  background-size: auto;
}

.social-icon {
  width: 25px;
  height: 25px;
}
.icon-custom {
  position: fixed;
  top: 25px;
  right: 55px;
  transition: all 0.5s;
}

.mr-25 {
  margin-left: 25px !important;
}


.floating-label {
  display: block;
  position: relative;
  max-height: 0;
  font-weight: 500;
  pointer-events: none;
  left: 15px;
}

.floating-label::before {
  color: var(--label__color);
  content: attr(data-content);
  display: inline-block;
  filter: blur(0);
  backface-visibility: hidden;
  transform-origin: left top;
  transition: transform 0.2s ease;
  left: 1rem;
  position: relative;
}

@media (max-width: 1080px){
.container {
    max-width: 100% !important;
    padding: 0 !important;
}
}

@media (min-width: 768px){
.d-md-block {
    display: block !important;
    max-width: 100% !important;
}

}


.floating-label::after {
  bottom: 1rem;
  content: "";
  height: 0.1rem;
  position: absolute;
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
  opacity: 0;
  left: 0;
  top: 100%;
  margin-top: -0.1rem;
  transform: scale3d(0, 1, 1);
  width: 100%;
  background-color: var(--color__accent);
}
#header {
  background: transparent !important;
}
.headerId {
    margin-left: 11%;
    padding: 16px 0px !important;
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: -15px;
}


.Typewriter__cursor {
  display: none;
}
.Typewriter{
  position: absolute;
}

.form-control-material .form-control,
.form-control-material .StripeElement {
  padding-top: 1.4rem;
  border-radius: 10px;
}

.btn-primary {
  border-radius: 10px;
  background-color: #dedfe9;
  color: #012169;
  font-weight: 500;
}

element.style {
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.btn-primary:hover,
.subnav li > a:hover,
.analytics-consent .analytics-banner .submit button:hover {
  color: #fff;
  background-color: #012169;
  border-color: transparent;
}

#second-join-now {
  background-color: #012169;
  color: #fff;
}

.bg-color-3 {
  top: 0;
  transition: all 0.5s;
}

.triangle-container {
  position: relative;
  opacity: 0;
  transition: all 0.5s;
}

.triangle-container.t-show {
  opacity: 1;
  transition: all 0.5s ease;
}

.triangle {
  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 0px solid transparent;
  border-right: 26.5px solid transparent;
  border-top: 30px solid #1b1464;
  transition: all 0.5s;
}

.triangle:last-child {
  right: 0;
  border-left: 26.5px solid transparent;
  border-right: 0px solid transparent;
}

@media screen and (max-width: 750px) {
  .header-custom {
    /* min-height: 0px !important; */
    height: 100vh !important;
  }
  .form-control-material .form-control,
  .form-control-material .StripeElement {
    padding-top: 1.4rem;
    border-radius: 10px;
    border: 1px solid #000;
  }
  .floating-label {
    display: block;
    position: relative;
    max-height: 0;
    font-weight: 500;
    pointer-events: none;
    color: #000;
  }
  .floating-label::before {
    color: #000;
  }

  .floating-label::after {
    color: #000;
  }
  .form-control-material .form-control:focus,
  .form-control-material .StripeElement:focus,
  .form-control-material .custom-select:focus {
    box-shadow: none;
    border-color: #000;
  }
  .form-control,
  .StripeElement {
    color: #000;
    border: 0.0625rem solid #000;
  }
  .form-control:focus,
  .StripeElement:focus {
    color: #000;
    border-color: #012169;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    padding: 0px !important;
    overflow: hidden;
  }
}

@media screen and (max-width: 400px) {
  .dialog-image-container {
    width: 400px !important;
    background-size: contain !important;
    background-image: url("./assets/hero_modal.png") !important ;
    /* background: url('./assets/hero_modal.png') no-repeat center !important; */
  }
  .dialog-content {
    margin-bottom: 150px !important;
    position: relative;
    bottom: 10px !important;
  }
}

.share-dialog-wrapper {
  height: 100vh;
  width: 100vw;
  z-index: 4;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.dialog-image-container {
  background: url("./assets/hero_modal.png") no-repeat;
  background-size: cover;
  width: 600px;
  height: 600px;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

#close {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 50px;
  transform: rotate(44deg);
  color:#fff
}

.dialog-content {
  color: #fff;
  padding: 20px 40px;
  font-weight: bold;
  text-align: center;
}

.dialog-head {
  font-size: 24px;
}

.dialog-text {
  font-weight: 400;
  font-size: 14px;
}

.share-dialog-button {
  border-radius: 9px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 10px 0;
}

.share-dialog-button img {
  height: 35px;
  margin-right: 25px;
}

.share-dialog-button div {
  min-width: 90px;
}

.Thanks-for-signing-u {
  width: 166.5px;
  height: 48px;
  margin: 22px 526px 1.5px 0;
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: #fff;
}
.How-would-you-like-t {
  width: 235.5px;
  height: 9.7px;
  margin: 12.3px 457px 13.5px 0;
  font-family: revert;
  font-size: 23px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: normal;
  color: #fff;
  position: relative;
  bottom: 10px;
}

.tc {
  position: relative;
  top: 15px;
}

.signup-text {
  width: 144px;
  font-size: 50px;
  font-family: "revert";
  position: relative;
  bottom: 25px;
}
.checkbox-group {
  /* position: relative;
    top: 20px; */
}
.get-involve-checkbox {
  margin: 13.5px 7.5px 7.5px 0;
  border-radius: 2px;
  border: solid 0.5px #fff;
}

.get-involve-checkbox-label {
  margin: 14px 0px 7.3px 7.5px;
  font-size: 19px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #fff;
}
.Do-you-like-this-pag {
  width: 110.5px;
  height: 14.7px;
  margin: 18.6px 23.5px 15.3px 0;
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #002169;
}
.privarcy-text {
  width: 599px;
  /* height: 2454.9px; */
  /* font-family: Inter; */
  margin: 0 0 18.6px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #002169;
  display: contents;
}

.footer-privarcy {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
.like {
  width: 60px;
  height: 22.5px;
  margin-right: 15px;
}
.share {
  width: 52px;
  height: 22.5px;
  margin-right: 15px;
}
.Sign-up-to-see-what {
  width: 181.5px;
  height: 14.7px;
  margin: 19.3px 283.5px 3.8px 13.5px;
  font-family: Inter;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #000;
}
/* .Sign-up-to-see-what .text-style-1 {
  color: #1758e7;
} */

.Sign-up-to-text{
  color: #000
}
.Sign-up-to-text a{
  color: rgb(27, 134, 233)
}
.tweet {
  width: 74px;
  height: 22.5px;
  margin-right: 15px;
}

.header-custom {
  min-height: "50vh";
}

.ssubmit-button {
    width: 28%;
    height: 45px;
    margin-top: 23px;
    margin-left: 30px;
    display: flex;
    border-radius: 15px;
    background-color: #fff;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.SAVE-INFO {
  width: max-content;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #002169;
}

.involve-error {
  color: red;
}

.success-dialog-container {
  background: linear-gradient(to right, #1bf708, #2a6d27);
  height: 230px;
  width: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-items: center;
}

.success-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: space-evenly;
}

.success-dialog-btn {
  display: flex;
  align-items: center;
  flex: 1;
}

.error-block {
  color: red;
  font-weight: 500;
  border: 1px solid;
  border-radius: 8px;
  text-align: center;
  margin-top: 10px;
}
.error-block-submit{
  color: red;
  font-weight: 500;
  border: 1px solid;
  border-radius: 8px;
  text-align: center;
  margin-top: 10px;
  display:flex;
  width:fit-content;
  justify-content: flex-start;
  padding: 2px 10px;

}
/* ----------------------------------- */
.form-control,
.StripeElement {
  padding-left: 15px !important;
}

.btn-primary:focus,
.subnav li > a:focus:hover,
.analytics-consent .analytics-banner .submit button:focus,
.btn-primary.focus,
.subnav li > a.focus:hover,
.analytics-consent .analytics-banner .submit button.focus {
  box-shadow: none !important;
}

.web_container {
  margin-top: 80px;
  margin-left: 85px;
}

.privacy-header-responsive {
  background-image: url("./assets/hero_privacy.png") !important ;
  min-height: 45vh !important;
  background-repeat: no-repeat;
  /* background-size:100% 100% !important;  */
  /* background-position: 0px 0px; */

  /* max-height: 50vh !important; */
 
}

.container{
  min-height: 70% !important;
}

.float-feeds {
    position: fixed;
    /* top: 20vh;
    left: -5.5vw; */
    top: 150px;
    left: -90px;
    z-index: 100;
    display: flex;
    transform: rotate(-90deg);
}
.float-feeds-button {
    /* width: 6vw;
    height: 4vh; */
        width: 100px;
    height: 35px;
   margin-right: 10px;
    border-radius: 0px 0px 1vw 1vw;
}
.feed-flex{
  display: flex;
    justify-content: space-around;
    cursor: pointer
}
.twitter{
    background-color: #479BE9;
}
.linkedin{
      background-color: #2277B5;

}
.float-feeds-txt{
  text-align: center;
  font-weight: 500;
  color: #fff
/* transform: rotate(-90deg); */
}




#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

/* #menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
} */

/* #menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
} */

/* #menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
} */
.menu-button-container , .scroll-view {
display: none !important;  
}
.float-feeds-responsive{
  display: none !important;  
}

.float-feeds-wrapper{
  display: none;
}

.flex_sb_center{
  display: flex;
  justify-content:space-between;
  align-items:center;
  margin-top: 40px

}

@media screen and (min-width: 1520) {
  .yt-videos{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 80%;
  max-height: 350px !important;
  margin:auto
}
}


.yt-videos{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 80%;
  /* max-height: 350px !important; */
  margin:auto
}
.yt-v{
  margin-right: 5px!important;
  margin-bottom: 5px!important;
 min-height: 280px
}
.yt-v:hover {
  /* box-shadow: 0 7px 40px 0 rgba(0,0,0,1); */
   /* height:280px; */
  /* width:450px;  */
}
.tweet_feeds_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
    overflow: auto;
    max-height: 80vh;
}
.tweet_feeds_card {
    height: 400px;
    width: 300px;
    background-color: #fff;
    border-radius: 10px;
    margin-right: 50px;
    margin-bottom: 20px;
}
.tweet_feeds_title{
  color:#000
}

.linkedin_feeds_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
    overflow: auto;
    max-height: 80vh;
}
.linkedin_feeds_card {
    height: 400px;
    width: 300px;
    background-color: #fff;
    border-radius: 15px;
    margin-right: 50px;
    margin-bottom: 20px;
    overflow: hidden;
}
.linkedin_feeds_title{
  color:#fff;
  background-color: rgb(54, 107, 255);
  height: 40px;
  text-align: center;
  line-height: 35px;
}

@media screen and (max-width: 670px) { 
/* and (min-width: 370px) */
.float-feeds-responsive{
  display: flex !important;  
  background-color: #000;
  height: 50% !important;
}
.float-feeds{
  display: none;
}
.arrow-animation{
animation-name: example;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  position:relative;
  animation-direction: alternate-reverse;  
  animation-timing-function: linear; 
}

@keyframes example {
  from {top: -20px;}
  to {top: 0px;}
}

.float-feeds-wrapper{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  display: block;
  background: #00000023;
  backdrop-filter: blur(10px);
}

.float-close-button{
/* transform:rotate(45deg);
font-size: 25px;
color: #f803bb */
}

.feed-buttons{
  margin-top:50px;
}
.topnav-btn{
    margin-bottom: 15px;
    width: 80%;
    height: 40px;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    padding: 0px 20px 0px 20px;
}
.sidebar_head{
    font-size: 24px;
    font-weight: 500;
    color: #000000;
}

.float-feeds-container{
    height: 100vh;
    width: 80vw;
    background: #ffffff;
    border-radius: 0px 25px 25px 0px;
    padding: 15px 30px 15px 30px;
}


  .container-padding{
    padding:20px;
}

.logo-center-responsive{
    display: flex;
    flex: 1;
    justify-content: center;
    /* align-items: center; */
}


.container{
  padding: 0px;
}

  .web_container {
    margin-left: 00px;
  }
    .menu-button-container {
    display: flex !important;
    position: absolute;
    top: 35px;
    left: 25px;
  }
.scroll-view{
    display: flow-root !important;
  width: 100%;
    position: absolute;
    bottom: 20px;
    text-align: center;
    justify-content: center;
}
  /* .navbar-brand-image {
    max-width: 7rem;
  } */

  /* .hero-container.hero-homepage {
    background-color: #e4e3f0 !important;
    height: 100px !important;
} */
}

@media screen and (min-width: 200px) and (max-width: 1000px) {
  .privacy-header-responsive {
  background: url("./assets/hero_privacy.png");
  background-position: 80% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 45vh !important;

}
}

@media (max-width: 767.98px){
.navbar-expand-md {
    padding: 0 !important;
    width: 100%;
    box-sizing: border-box;
    /* overflow-y: auto; */
}
}


/* Iframes Designs */
/* iframe:hover {
    width: 400px !important;
    height:230px !important;
    margin-top: 0px !important;
    z-index: 999999;
} */
iframe#video1:hover {
    width: 450px !important;
    height:250px !important;
    margin-top: 0px !important;
    z-index: 999999;
}
 iframe#video2:hover {
    width: 450px !important;
    height:250px !important;
    margin-top: 0px !important;
    z-index: 999999;
}
iframe#video3:hover {
    width: 450px !important;
    height:250px !important;
    margin-top: 0px !important;
    z-index: 999999;
} 
iframe {
  /* max-height: 226px; */
    overflow: hidden;
    transition: 0.5s;
}